import { cssBundleHref } from "@remix-run/css-bundle";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration } from
"@remix-run/react";
import type { LinksFunction } from "partymix";

import rdtCss from "remix-development-tools/index.css";

import globalCss from "./global.css";
import tailwindCss from "./tailwind.css";

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: globalCss },
{ rel: "stylesheet", href: tailwindCss },
...(process.env.NODE_ENV === "development" ?
[{ rel: "stylesheet", href: rdtCss }] :
[]),
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>);

}

let AppExport = App;
if (process.env.NODE_ENV === "development") {
  const { withDevTools } = await import("remix-development-tools");
  AppExport = withDevTools(AppExport);
}

export default AppExport;